import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Preferences } from '@capacitor/preferences';
import { CONFIGS, PathConfig } from 'mobile-web/services/migration';
import { service } from '@ember/service';
import BootstrapService from 'mobile-web/services/bootstrap';
import style from './index.m.scss';
import DeviceService from 'mobile-web/services/device';

interface Args {
  // Required arguments

  // Optional arguments
  onClose?: Action;
  testSelector?: string;
}

interface Signature {
  Args: Args;
}

export default class DeveloperMenu extends Component<Signature> {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service device!: DeviceService;

  // Tracked properties
  @tracked changeUrlOptionModalIsOpen = false;
  @tracked vendorSlugModalIsOpen = false;
  @tracked menuItemModalIsOpen = false;
  @tracked serveNextPageSelectionModalIsOpen = false;
  @tracked devMenuOption = '';
  @tracked vendorSlug = '';
  @tracked menuItem = '';
  @tracked selectedServeNextRoutes: PathConfig[] = [];

  // Untracked properties
  style = style;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);

    for (const config of CONFIGS) {
      const isSet = localStorage.getItem(`olo-local/${config.cookieName}`) == '1';

      if (isSet) {
        this.selectedServeNextRoutes.push(config);
      }
    }
  }

  // Getters and setters
  get serveNextRouteOptions(): PathConfig[] {
    const fullyMigratedRoutes = this.bootstrap.data?.serveNextConfig.pathConfigs.filter(
      c => c.fullyMigrated === true
    );

    return CONFIGS.filter(
      config => !fullyMigratedRoutes?.some(route => route.cookieName === config.cookieName)
    );
  }
  // Lifecycle methods

  // Other Methods
  async handleBaseUrlAction(): Promise<void> {
    const baseUrlKey = 'JS_BASE_URL';
    const { value } = await Preferences.get({ key: baseUrlKey });

    // eslint-disable-next-line no-alert
    const inputBaseUrl = window.prompt(
      'Enter backend url. Clear the input value to remove the existing url. Force close the app after pressing "OK"',
      value ?? ''
    );
    await Preferences.set({ key: baseUrlKey, value: inputBaseUrl ?? '' });
  }

  goToFirstProduct() {
    const products = document.body.querySelectorAll('[data-test-productcard]');
    if (products.length === 0) {
      return;
    }
    const product = products[0];
    if (product instanceof HTMLElement) {
      product.click();
    }
  }

  // Tasks

  // Actions and helpers
  @action
  async handleDevMenuSubmit() {
    const option = this.devMenuOption.trim();

    if (option === '1') {
      await this.handleBaseUrlAction();
    } else if (option === '2') {
      this.vendorSlugModalIsOpen = true;
    } else if (option === '3') {
      this.menuItemModalIsOpen = true;
    } else if (option === '4') {
      this.goToFirstProduct();
      this.close();
    } else if (option === '5') {
      this.serveNextPageSelectionModalIsOpen = true;
    }
  }

  @action
  close() {
    this.args.onClose?.();
  }

  @action
  handleVendorSlugAction(): void {
    if (this.vendorSlug) {
      this.close();
      window.location.href = `/menu/${this.vendorSlug}`;
    }
  }

  @action
  handleMenuItemAction(): void {
    if (this.vendorSlug && this.menuItem) {
      this.close();
      window.location.href = `/menu/${this.vendorSlug}/products/${this.menuItem}`;
    }
  }

  @action
  handleServeNextPageSelectionToggle(option: PathConfig): void {
    let currentSelectedRoutes = [...this.selectedServeNextRoutes];
    if (currentSelectedRoutes.some(opt => opt.cookieName === option.cookieName)) {
      currentSelectedRoutes = currentSelectedRoutes.filter(
        opt => opt.cookieName !== option.cookieName
      );
    } else {
      const selectedOption = CONFIGS.find(pc => pc.cookieName === option.cookieName);

      if (!selectedOption) {
        return;
      }

      currentSelectedRoutes.push(selectedOption);
    }

    this.selectedServeNextRoutes = currentSelectedRoutes;
  }

  @action
  isServeNextPageSelected(option: PathConfig): boolean {
    return this.selectedServeNextRoutes.some(opt => opt.cookieName === option.cookieName);
  }

  @action
  async handleServeNextPageSelectionSubmit(): Promise<void> {
    const removedRoutes =
      this.bootstrap.data?.serveNextConfig.pathConfigs.filter(
        c => !this.selectedServeNextRoutes.some(route => route.cookieName === c.cookieName)
      ) ?? [];

    removedRoutes.forEach(option => {
      localStorage.removeItem(`olo-local/${option.cookieName}`);
    });

    this.selectedServeNextRoutes.forEach(option =>
      localStorage.setItem(`olo-local/${option.cookieName}`, '1')
    );

    this.serveNextPageSelectionModalIsOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    DeveloperMenu: typeof DeveloperMenu;
  }
}
