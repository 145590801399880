import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import IntlService from 'ember-intl/services/intl';

import { TicketResponse } from 'mobile-web/adapters/ticket';
import { HeadingLevel } from 'mobile-web/components/heading';
import { isErr } from 'mobile-web/lib/result';
import Validation, { Binding, ValidationConfig, ValidationResult } from 'mobile-web/lib/validation';
import Vendor from 'mobile-web/models/vendor';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import OnPremiseService from 'mobile-web/services/on-premise';

import style from './index.m.scss';

interface Args {
  // Required arguments
  vendor: Vendor;

  // Optional arguments
  headingLevel?: HeadingLevel;
  ticketResponse?: TicketResponse;
}

interface Signature {
  Args: Args;
}

export default class RoutesPayRoute extends Component<Signature> {
  // Service injections
  @service analytics!: AnalyticsService;
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service intl!: IntlService;

  // Untracked properties
  style = style;

  // Tracked properties
  @tracked tableNumber = '';
  @tracked validationResult?: ValidationResult;

  // Getters and setters
  get validationConfig(): ValidationConfig<RoutesPayRoute> {
    const commonBindings: Binding<RoutesPayRoute>[] = [
      {
        targetProp: 'tableNumber',
        ruleName: 'emptyTableNumber',
        message: this.intl.t('mwc.pay.errors.tableNumber'),
      },
    ];

    return {
      bindings: commonBindings,
      customRules: [
        {
          name: 'emptyTableNumber',
          check: (value: string) => value.length >= 1,
        },
      ],
    };
  }

  // Lifecycle methods

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  findCheck(event: Event) {
    event.preventDefault();

    this.validationResult = Validation.validate(this, this.validationConfig);
    if (isErr(this.validationResult)) {
      return;
    }

    this.analytics.trackEvent(AnalyticsEvents.PayAtTableClickedFindCheck, () => ({
      [AnalyticsProperties.TableNumber]: this.tableNumber,
    }));

    this.router.transitionTo('pay-select', this.args.vendor.slug, {
      queryParams: { tableNumber: this.tableNumber },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::PayRoute': typeof RoutesPayRoute;
  }
}
