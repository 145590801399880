import { set } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';
import RSVP from 'rsvp';

import { buildOloErrorHandler, FriendlyError } from 'mobile-web/lib/errors';
import { channelSlug } from 'mobile-web/lib/hybrid-util';
import { Status } from 'mobile-web/lib/utilities/http';
import isSome from 'mobile-web/lib/utilities/is-some';
import BootstrapDataModel from 'mobile-web/models/bootstrap-data';
import BasketService from 'mobile-web/services/basket';
import ErrorService from 'mobile-web/services/error';
import FeaturesService from 'mobile-web/services/features';
import GroupOrderService from 'mobile-web/services/group-order';
import OnPremiseService from 'mobile-web/services/on-premise';
import * as OrderCriteriaService from 'mobile-web/services/order-criteria';
import OrderItAgainService from 'mobile-web/services/order-it-again';
import SessionService from 'mobile-web/services/session';
import StorageService from 'mobile-web/services/storage';
import VendorService from 'mobile-web/services/vendor';

// Pull objects from bootstrap data that are needed directly in the store
export function primeTheStore(store: DS.Store, data: BootstrapDataModel): void {
  const channel = data.channel;
  const timeWantedModes = data.timeWantedModes;
  const handoffModes = data.handoffModes;
  const customLinks = data.customLinks;

  const payload: AnyObject = {
    timeWantedModes,
    handoffModes,
    channel,
  };
  if (customLinks) {
    payload.customLinks = customLinks;
  }

  store.pushPayload(payload);
}

export default class BootstrapService extends Service {
  // Service injections

  // Untracked properties
  @service('error') error!: ErrorService;
  @service store!: DS.Store;
  @service session!: SessionService;
  @service('basket') basketService!: BasketService;
  @service('features') features!: FeaturesService;
  @service('vendor') vendorService!: VendorService;
  @service orderCriteria!: OrderCriteriaService.default;
  @service storage!: StorageService;
  @service onPremise!: OnPremiseService;
  @service groupOrder!: GroupOrderService;
  @service orderItAgain!: OrderItAgainService;

  // Tracked properties
  @tracked data?: BootstrapDataModel;
  @tracked initBootstrapFailed = false;
  @tracked cloudflareHeader?: string;

  // Getters and setters
  get isTranslated(): boolean {
    return this.data?.translationTest !== this.data?.decodedTranslation;
  }

  get shouldRedirectToCustomSite(): boolean {
    if (!this.data) return false;

    return !!this.data.redirectToCustomSite && !!this.data.customSiteUrl;
  }

  get customSiteUrl(): string {
    return this.data?.customSiteUrl ?? '';
  }

  get showPrivacyLink(): boolean {
    return this.data?.showPrivacyLink ?? true;
  }

  get showUserAgreementLink(): boolean {
    return this.data?.showUserAgreementLink ?? true;
  }

  get checkForHouseAccountOnPasswordReset(): boolean {
    return this.data?.checkForHouseAccountOnPasswordReset ?? false;
  }

  /**
   *  This property is used to determine if the user is a legacy user that can be upgraded to an Olo Auth (Borderless) account.
   */
  get supportsLegacyLoginUpgrade(): boolean {
    const upgradeMode = this.data?.oloAuthConfig.legacyLoginUpgradeMode;

    if (upgradeMode) {
      return upgradeMode !== 'Disabled';
    }

    return false;
  }

  // Lifecycle methods

  // Other methods
  async initBootstrap(): Promise<{ bootstrapData: BootstrapDataModel }> {
    this.initBootstrapFailed = false;

    // if these bootstrap promises fail continue to app with no saved state.
    const logError = (errorOrMessage: string | Error) => {
      const err = errorOrMessage instanceof Error ? errorOrMessage : new Error(errorOrMessage);
      // @ts-ignore
      if (err && Array.isArray(err.errors) && err.errors.filter(x => x.status === '401').length) {
        return;
      }
      this.error.sendExternalError(
        err,
        {
          cause: err.message,
          stack: err.stack,
          cookiesEnabled: navigator.cookieEnabled,
        },
        ['application-route']
      );
    };

    await this.session.asyncInit();

    return this.store
      .findRecord('bootstrap-data', channelSlug())
      .then(async bootstrapData => {
        primeTheStore(this.store, bootstrapData);
        set(this, 'data', bootstrapData);

        await this.session.validateServeAppToken();

        // Ensure feature flags are set up first
        await this.features.setupFeatureFlags();

        const bootstrapUserId = bootstrapData.userId;
        const bootstrapBasketId = bootstrapData.basketGuid;
        const bootstrapBasketVendor = bootstrapData.basketVendorSlug;
        const maybeUser =
          bootstrapUserId && !this.session.isLoggedIn
            ? this.session.loadUser(bootstrapUserId)
            : RSVP.resolve(undefined);

        const maybeVendor = bootstrapBasketVendor
          ? this.vendorService.ensureVendorLoaded(bootstrapBasketVendor)
          : RSVP.resolve(undefined);
        return (
          RSVP.hash({
            user: maybeUser,
            vendor: maybeVendor,
            bootstrapData,
          })
            .then(() => {
              const currentlyLoadedBasket = this.basketService.basket;

              if (isSome(currentlyLoadedBasket)) {
                return RSVP.resolve(currentlyLoadedBasket);
              }

              if (isSome(bootstrapBasketId)) {
                return this.basketService.loadBasket(bootstrapBasketId);
              }

              return RSVP.resolve(undefined);
            })
            // this must be in sync with components/routes/group-order/join-route/index.ts confirm()
            .then(() => this.groupOrder.loadGroupOrder())
            .then(() => this.onPremise.onBootstrapInit())
            .then(() => {
              if (this.features.flags['abtest-order-it-again-prereq-olo-61589']) {
                if (this.orderItAgain.currentVendorRecentOrder) {
                  if (
                    this.storage.orderItAgainCohort &&
                    this.storage.orderItAgainCohort !== 'OFF'
                  ) {
                    return;
                  }
                  if (this.features.flags['abtest-order-it-again-olo-58312']) {
                    this.storage.orderItAgainCohort = 'Test';
                  } else {
                    this.storage.orderItAgainCohort = 'Control';
                  }
                  this.orderItAgain.orderItAgainAnalytics();
                }
              } else {
                this.storage.orderItAgainCohort = 'OFF';
              }
            })
        );
      })
      .catch(
        buildOloErrorHandler('app-load-failure', reason => {
          if (isSome(reason) && !isEmpty(reason.errors)) {
            const status = parseInt(reason.errors[0].status, 10);
            if (status === Status.Forbidden && !navigator.cookieEnabled) {
              return RSVP.reject(
                new FriendlyError(
                  'Please enable cookies',
                  'It appears that you have cookies disabled. In order to use this site, please enable cookies and refresh the page.'
                )
              );
            }
          }
          logError(reason);
          this.initBootstrapFailed = true;
          return RSVP.reject(reason);
        })
      );
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    bootstrap: BootstrapService;
  }
}
