import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { App, AppInfo } from '@capacitor/app';
import { AppLauncher } from '@capacitor/app-launcher';
import { Browser } from '@capacitor/browser';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { WebView } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { compare } from 'compare-versions';
import IntlService from 'ember-intl/services/intl';
import { MediaService } from 'ember-responsive';
import debounce from 'lodash.debounce';

import ENV from 'mobile-web/config/environment';
import media from 'mobile-web/decorators/media';
import { appleStoreListingLink, googleStoreListingLink } from 'mobile-web/lib/app-store';
import { DigitalWalletPaymentData } from 'mobile-web/lib/payment';
import MetadataConfig from 'mobile-web/lib/plugins/metadata-config';
import NativeDigitalWallets from 'mobile-web/lib/plugins/native-digital-wallets';
import OloPayNative from 'mobile-web/lib/plugins/olo-pay-native';
import { GlobalDevice } from 'mobile-web/models/device';
import ChannelService from 'mobile-web/services/channel';
import SessionService from 'mobile-web/services/session';

import AnalyticsService, { AnalyticsEvents, AnalyticsProperties } from './analytics';
import BootstrapService from './bootstrap';
import ErrorService from './error';
import FeaturesService from './features';

// https://stackoverflow.com/a/9039885/4326495
const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
// https://stackoverflow.com/a/28236817/4326495
const IS_ANDROID = /(android)/i.test(navigator.userAgent);

export const LAST_BACKGROUNDED_KEY = 'lastBackgrounded';
export const SERVE_APP_TOKEN_KEY = 'serveAppToken';
export type Platform = 'ios' | 'android' | 'web';
export type Viewport = 'Desktop' | 'Tablet' | 'Mobile';
export default class DeviceService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service bootstrap!: BootstrapService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service session!: SessionService;
  @service intl!: IntlService;
  @service media!: MediaService;
  @service error!: ErrorService;

  // Untracked properties

  // Tracked properties
  @tracked appInfo: AppInfo | undefined;
  @tracked nativeUpdateRequired: boolean;
  @tracked viewport: Viewport;
  @media('(pointer: coarse)') touchScreen!: boolean;

  // Getters and setters
  get platform(): Platform {
    return Capacitor.getPlatform() as Platform;
  }

  get isIOS(): boolean {
    return this.platform === 'ios' || IS_IOS;
  }

  get isAndroid(): boolean {
    return this.platform === 'android' || IS_ANDROID;
  }

  get isWeb(): boolean {
    return this.platform === 'web';
  }

  get isHybrid(): boolean {
    return ENV.isHybrid || Capacitor.isNativePlatform();
  }

  get isWebIOS(): boolean {
    return this.isWeb && this.isIOS;
  }

  get isWebAndroid(): boolean {
    return this.isWeb && this.isAndroid;
  }

  get isWebOnly(): boolean {
    return this.isWeb && !this.isAndroid && !this.isIOS;
  }

  get isHybridIOS(): boolean {
    return this.isHybrid && this.isIOS;
  }

  get isHybridAndroid(): boolean {
    return this.isHybrid && this.isAndroid;
  }

  get appId(): string | undefined {
    return (
      this.appInfo?.id ??
      (this.isHybridAndroid
        ? this.channel.current?.androidAppIdentifier
        : this.isHybridIOS
        ? this.channel.current?.iOSAppIdentifier
        : undefined)
    );
  }

  get nativeAppVersion(): string | undefined {
    return this.appInfo?.version ?? undefined;
  }

  get storeId(): string | undefined {
    return this.isHybridAndroid
      ? this.appInfo?.id
      : this.isHybridIOS
      ? window.Olo.appStoreId
      : undefined;
  }

  // Lifecycle methods
  constructor() {
    super(...arguments);

    if (Capacitor.isNativePlatform()) {
      (async () => {
        // App.getInfo throws an error in web
        this.appInfo = await App.getInfo();
      })();
    }
    this.nativeUpdateRequired = false;
    this.viewport = this.determineViewport();
    this.listenForViewportChange();
  }

  // Other methods
  async exitApp(): Promise<void> {
    if (this.isHybridAndroid) {
      await App.exitApp();
    }
  }

  async openInAppBrowser(url: string): Promise<void> {
    await Browser.open({ url });
  }

  async launchApp(url: string): Promise<void> {
    await AppLauncher.openUrl({ url });
  }

  serializeDeviceForGlobalData(): GlobalDevice {
    return {
      isHybrid: this.isHybrid,
      isHybridAndroid: this.isHybridAndroid,
      isHybridIOS: this.isHybridIOS,
    };
  }

  async checkForNativeUpdate(skipMinWaitTime = false): Promise<void> {
    if (
      this.isHybrid &&
      this.storeId &&
      ((await this.shouldCheckForNativeUpdate()) || skipMinWaitTime) &&
      (await this.isNativeUpdateRequired())
    ) {
      this.nativeUpdateRequired = true;
      await Dialog.alert({
        title: this.intl.t('mwc.nativeUpdateModal.title'),
        message: this.intl.t('mwc.nativeUpdateModal.message'),
        buttonTitle: this.isHybridAndroid
          ? this.intl.t('mwc.nativeUpdateModal.positiveButtonAndroid')
          : this.intl.t('mwc.nativeUpdateModal.positiveButtoniOS'),
      });

      const appStoreLink = this.isAndroid
        ? googleStoreListingLink(this.storeId)
        : appleStoreListingLink(this.storeId);

      await AppLauncher.openUrl({ url: appStoreLink });
    } else {
      this.nativeUpdateRequired = false;
    }
  }

  async storageGet(key: string): Promise<string | undefined> {
    if (this.isHybrid) {
      const result = await Preferences.get({ key });
      return result?.value ?? undefined;
    }
    return Promise.resolve(undefined);
  }

  async storageRemove(key: string): Promise<void> {
    if (this.isHybrid) {
      await Preferences.remove({ key });
    }
  }

  async storageSet(key: string, value: string): Promise<void> {
    if (this.isHybrid) {
      await Preferences.set({ key, value });
    }
  }

  async getNativeDigitalWalletsPaymentData(
    totalAmount: number
  ): Promise<{ digitalWalletData: DigitalWalletPaymentData | undefined }> {
    if (!this.isCapacitorPluginAvailable('NativeDigitalWallets')) {
      return { digitalWalletData: undefined };
    }
    return NativeDigitalWallets.getPaymentData({ totalAmount });
  }

  /**
   * Parameters for the gateway, as a JSON-formatted string. For more details, see and parameters for specific gateways, see:
   * Google's [Gateway](https://developers.google.com/pay/api/android/reference/request-objects#gateway)
   * documentation
   *
   * Example JSON Object:
   * {
   *   "gateway": "exampleGateway",
   *   "gatewayMerchantId": "exampleGatewayMerchantId"
   * }
   */
  async setNativeGooglePayGateway(gatewayParameters: string): Promise<void> {
    if (this.isCapacitorPluginAvailable('NativeDigitalWallets')) {
      await NativeDigitalWallets.setPaymentGateway({ gatewayParameters });
    }
  }

  async nativeDigitalWalletsReady() {
    if (!this.isCapacitorPluginAvailable('NativeDigitalWallets')) {
      return { isReady: false };
    }
    return NativeDigitalWallets.digitalWalletsReady();
  }

  async addNativeDigitalWalletsReadyListener(
    listenerFunc: (isReady: boolean) => void
  ): Promise<PluginListenerHandle | undefined> {
    if (!this.isCapacitorPluginAvailable('NativeDigitalWallets')) {
      return undefined;
    }

    return NativeDigitalWallets.addListener(
      'digitalWalletsReadyEvent',
      async () =>
        await this.nativeDigitalWalletsReady().then(({ isReady }) => {
          listenerFunc(isReady);
        })
    ).then(listener => listener);
  }

  isCordovaPluginAvailable(name: string) {
    const plugins = cordova.require('cordova/plugin_list').metadata;
    return typeof plugins[name] !== 'undefined';
  }

  isCapacitorPluginAvailable(name: string) {
    return Capacitor.isPluginAvailable(name);
  }

  isOloPayNativeAvailable() {
    return OloPayNative.isPaymentProviderAvailable();
  }

  async nativeCapacitorVersion() {
    const key = this.isHybridAndroid
      ? 'com.olo.serveapp.capacitorversion'
      : this.isHybridIOS
      ? 'OloCapacitorVersion'
      : undefined;

    return key && this.isCapacitorPluginAvailable('MetadataConfig')
      ? MetadataConfig.read({ key })
      : undefined;
  }

  async fileExists(path: string) {
    if (!this.isHybrid) {
      return false;
    }

    try {
      await Filesystem.stat({ path, directory: Directory.Data });
      return true;
    } catch {
      return false;
    }
  }

  async hasServeNextFiles() {
    return (
      (await this.fileExists('serve-next-index.html')) &&
      (await this.fileExists('ember-index.html'))
    );
  }

  async renderHybridServeNext(nextRoute: string) {
    if (!(await this.hasServeNextFiles())) {
      throw new Error('Serve Next files are missing for hybrid.');
    }

    try {
      const serveNextHtml = await Filesystem.readFile({
        path: 'serve-next-index.html',
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });

      const indexHtml = await Filesystem.writeFile({
        path: 'index.html',
        data: serveNextHtml.data,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });

      await Preferences.set({
        key: 'serve-next-route',
        value: nextRoute,
      });

      const filePath = indexHtml.uri.split('file://')[1].split('/index.html')[0];
      await WebView.setServerBasePath({ path: filePath });
    } catch (e) {
      this.error.sendExternalError(e);
    }
  }

  private async shouldCheckForNativeUpdate() {
    const lastBackgrounded = await Preferences.get({ key: LAST_BACKGROUNDED_KEY });
    const minBackgroundingTime = this.nativeUpdateRequired ? 0 : 30;
    return (
      (Date.now() - new Date(parseInt(lastBackgrounded.value!, 10)).valueOf()) / 60000 >
      minBackgroundingTime
    );
  }

  private async isNativeUpdateRequired() {
    const nativeVersion = this.nativeAppVersion ?? '';

    const minimumVersion = this.isAndroid
      ? this.bootstrap.data?.channel.androidMinAppVersion
      : this.bootstrap.data?.channel.iOSMinAppVersion;

    if (!minimumVersion) {
      return false;
    }

    if (compare(minimumVersion, nativeVersion, '>')) {
      this.analytics.trackEvent(AnalyticsEvents.AppUpdateRequired, () => ({
        [AnalyticsProperties.AndroidMinAppVersion]:
          this.bootstrap.data?.channel.androidMinAppVersion,
        [AnalyticsProperties.IosMinAppVersion]: this.bootstrap.data?.channel.iOSMinAppVersion,
      }));
    }

    return compare(minimumVersion, nativeVersion, '>');
  }

  private listenForViewportChange() {
    let matches: unknown[];
    const cb = debounce(() => {
      if (matches !== this.media.matches) {
        this.viewport = this.determineViewport();
      }
      matches = this.media.matches;
    }, 100);
    window.addEventListener('resize', cb);
  }

  private determineViewport(): Viewport {
    if (this.media.isDesktop) {
      return 'Desktop';
    } else if (this.media.isTablet) {
      return 'Tablet';
    }
    return 'Mobile';
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    device: DeviceService;
  }
}
