import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';

import TicketModel from 'mobile-web/models/ticket';
import Ticket from 'mobile-web/models/ticket';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import OnPremiseService from 'mobile-web/services/on-premise';

import style from './index.m.scss';

interface Args {
  model: { checks: Array<Ticket>; tableNumber: string };
}

interface Signature {
  Args: Args;
}

export enum CheckStatus {
  NoCheck = 'NoCheck',
  OpenChecks = 'OpenChecks',
  AllPaid = 'AllPaid',
}
export default class PaySelectRoute extends Component<Signature> {
  @service analytics!: AnalyticsService;
  @service intl!: IntlService;
  @service router!: RouterService;
  @service onPremise!: OnPremiseService;

  style = style;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.analytics.trackEvent(AnalyticsEvents.PayAtTableSelectCheckPage, () => ({
      [AnalyticsProperties.NumberOfOpenChecks]: this.checks.filter(check => check.totals.due > 0)
        .length,
      [AnalyticsProperties.TableNumber]: this.tableId,
    }));
  }

  get checkStatus() {
    if (this.checks.length === 0) {
      return CheckStatus.NoCheck;
    } else if (this.checks.every(check => check.totals.due === 0)) {
      return CheckStatus.AllPaid;
    }
    return CheckStatus.OpenChecks;
  }

  get checks(): Array<Ticket> {
    return this.args.model.checks;
  }

  get tableId() {
    return this.args.model.tableNumber;
  }

  get headingText() {
    switch (this.checkStatus) {
      case CheckStatus.NoCheck:
        return this.intl.t('mwc.paySelect.noOpenChecks.title');
      case CheckStatus.AllPaid:
        return this.intl.t('mwc.paySelect.allPaid.title');
      default:
        return this.intl.t('mwc.paySelect.title');
    }
  }

  get subHeadingText() {
    switch (this.checkStatus) {
      case CheckStatus.NoCheck:
        return this.intl.t('mwc.paySelect.noOpenChecks.message');
      case CheckStatus.AllPaid:
        return this.intl.t('mwc.paySelect.allPaid.message');
      default:
        return this.intl.t('mwc.paySelect.information');
    }
  }

  // Tasks
  goToCheckoutTask = task(async (ticket: TicketModel): Promise<void> => {
    this.analytics.trackEvent(AnalyticsEvents.PayAtTableSelectCheckClick, () => ({
      [AnalyticsProperties.NumberOfOpenChecks]: this.checks.filter(
        checkObj => checkObj.totals.due > 0
      ).length,
      [AnalyticsProperties.CheckNumber]: ticket.ticketNumber,
      [AnalyticsProperties.TableNumber]: this.tableId,
    }));

    await this.onPremise.payAtTableCheckout(ticket);
  });

  // Actions and helpers
  @action
  async goToCheckout(ticket: TicketModel) {
    this.goToCheckoutTask.perform(ticket);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Routes::PaySelectRoute': typeof PaySelectRoute;
  }
}
