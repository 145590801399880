/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * A master error-handling service which handles **server-side** errors,
 * delegating them to the `user-error` service, the `external- logger` service,
 * or both as appropriate.
 *
 * NOTE: we allow the console in a few places here (overriding the ESLint config),
 *       but only in cases which are actively disabled in production.
 */

import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import DS from 'ember-data';

import Ember from 'ember';
import IntlService from 'ember-intl/services/intl';

import ENV from 'mobile-web/config/environment';
import { ErrorCategory, buildOloErrorHandler } from 'mobile-web/lib/errors';
import { isArray } from 'mobile-web/lib/utilities/_';
import * as Http from 'mobile-web/lib/utilities/http';
import isSome from 'mobile-web/lib/utilities/is-some';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
  EventOptions,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChallengeService from 'mobile-web/services/challenge';
import FeaturesService from 'mobile-web/services/features';
import OrderCriteriaService from 'mobile-web/services/order-criteria';
import UserFeedback, { Type } from 'mobile-web/services/user-feedback';

import BootstrapService from './bootstrap';
import HealthCheckService from './health-check';
import OnPremiseService from './on-premise';

export enum Errors {
  ForUser = 'ErrorForUser',
}

// TODO: This is a *weak* error type; we should check all uses and make sure
// they do as we think they should so we can upgrade this to actually be a
// more useful type.
export type CommonError = {
  // Not actually a title, maps from errorCode in ServerError
  title?: string;
  detail?: string;
  status?: Http.Status;
  message?: string;
  category?: ErrorCategory;
  traceId?: string;
};

export type ReadableErr = { errors: Array<CommonError> };
const hasErrorMessage = (err?: any): err is ReadableErr =>
  err &&
  typeof err === 'object' &&
  'errors' in err &&
  isArray(err.errors) &&
  err.errors.length > 0 &&
  isSome(err.errors[0].message);

type SomeError = CommonError | ReadableErr | string;

export const errorForUser = (error?: any): error is ReadableErr =>
  hasErrorMessage(error) && error.errors.some((err: any) => err?.title === Errors.ForUser);

export const getErrObj = (err?: SomeError): CommonError | undefined =>
  isNone(err) || typeof err === 'string' ? undefined : 'errors' in err ? err.errors[0] : err;

function isOrderCriteriaErrorCategory(category?: ErrorCategory): boolean {
  return (
    isSome(category) &&
    [
      ErrorCategory.HandoffRequirementsNotMet,
      ErrorCategory.TooFarInAdvance,
      ErrorCategory.VendorOutOfHours,
      ErrorCategory.WantedTimeNotSpecified,
      ErrorCategory.TimeWantedModeNotSpecified,
    ].includes(category)
  );
}

/*
 * Checks for specific error category caused by flaky POS connectivity.
 * Better fix is backend change to stop returning `isAdvance` on basket when the store is offline.
 */
function isPayAtTableConnectivityErrorCategory(
  isOnPremise: boolean,
  category?: ErrorCategory
): boolean {
  return (
    isSome(category) &&
    [ErrorCategory.VendorDisabled, ErrorCategory.PosOffline].includes(category) &&
    isOnPremise
  );
}

/*
 * Checks when POS returns specific errors indicating the check has been fully paid.
 */
function isPayAtTableCheckAlreadyPaid(message?: string): boolean {
  return (
    isSome(message) &&
    ['This check has already been paid', 'Check was already closed'].includes(message)
  );
}

export function isCvvRevalidationErrorCategory(category?: ErrorCategory): boolean {
  return (
    isSome(category) && [ErrorCategory.PaymentTransactionDeclinedCvvRevalidation].includes(category)
  );
}

// See MWC-2237
export type ServerError = {
  errorCode?: string;
  message?: string;
  category?: string; // mapped in application adapter
};

export const DEFAULT_TITLE = 'Something Is Wrong';

export const MOBO_ERR =
  'Sorry, something went wrong with your request. Please try again in a few minutes.';

export const enum RAYGUN_TAGS {
  // user messages
  MESSAGE_SOMETHING_WRONG = 'something-wrong',

  // causes
  CAUSE_SERVER_ERROR = 'server-error',
  CAUSE_JS_ERROR = 'js-error',
  CAUSE_UNDEFINED_ERROR = 'undefined-error',

  // origins
  ORIGIN_ERROR_SERVICE = 'error-service',

  // source files
  SOURCE_NO_STACK = 'no-stack',
  SOURCE_GTM = 'gtm',
  SOURCE_INTERNAL = 'internal',
  SOURCE_VENDOR = 'vendor',
  SOURCE_EXTERNAL = 'external',
}

export const NO_BASKET_ERR = 'You must have an item in your basket before you can check out.';

const UNAUTHORIZED_ERR = 'You must be logged in to do that.';

export const FORBIDDEN_RESPONSE_TEXT =
  'Sorry, we ran into an issue. Try reloading this page – that should do the trick!';

export const getErrorStatus = (error?: SomeError): number | undefined =>
  isNone(error) || typeof error === 'string'
    ? undefined
    : 'status' in error
    ? error.status
    : 'errors' in error
    ? error.errors[0]?.status
    : undefined;

const isStatus = (status: Http.Status, error?: SomeError): boolean =>
  Http.isStatus(status, getErrorStatus(error) ?? '');

const isServerError = (error?: SomeError): boolean => {
  const errorStatus = getErrorStatus(error);

  // get status of the health check endpoint on errors above 500
  return errorStatus ? errorStatus >= Http.Status.InternalServerError : false;
};

/** Check error format and extract the human readable message */
export const extractErrorDetail = (err?: any): string | undefined =>
  hasErrorMessage(err)
    ? err.errors[0].message
    : typeof err === 'string'
    ? err
    : 'detail' in err
    ? err.detail
    : undefined;

export default class ErrorService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service basket!: BasketService;
  @service bootstrap!: BootstrapService;
  @service challenge!: ChallengeService;
  @service features!: FeaturesService;
  @service healthCheck!: HealthCheckService;
  @service intl!: IntlService;
  @service onPremise!: OnPremiseService;
  @service orderCriteria!: OrderCriteriaService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service userFeedback!: UserFeedback;

  // Untracked properties

  // Tracked properties

  // Getters and setters
  get basketId(): string | undefined {
    try {
      return String(this.basket.basket?.id);
    } catch {
      // If we're already handling an error, the app might not be in a state
      // where it's possible to look up the basket. Silently fail in this case.
      return undefined;
    }
  }

  // Lifecycle methods
  constructor() {
    super(...arguments);

    Ember.onerror = buildOloErrorHandler('Ember.onerror', error => {
      this.reportError(error);
    });
  }

  willDestroy(): void {
    // @ts-ignore -- this is required by Ember's error handling checking
    Ember.onerror = undefined;
  }

  // Other methods
  /**
   * This method is the primary error reporting method.
   * It will cause a debug point in development, log the error out to the console,
   * send any relevant user messaging, redirect to login page if needed,
   * and send events to mixpanel and raygun. This should be the go-to method
   * unless you have a solid reason to use the other methods.
   */
  reportError = buildOloErrorHandler('Olo.reportError', error => {
    if (ENV.APP.PAUSE_ON_ERRS) {
      debugger; // eslint-disable-line no-debugger
    }

    if (ENV.APP.LOG_ALL_ERRS) {
      try {
        console.error(error); // eslint-disable-line no-console
      } catch (e) {
        // ignore errors from logging
      }
    }

    this.handleError(error);
  });

  /**
   * This method sends an error directly to Raygun. It does not cause any other
   * side effects within the system.
   */
  sendExternalError(
    error?: Error | string,
    extraProperties?: Dict<string | undefined | boolean>,
    tags: Array<string> = []
  ): void {
    if (this.isIgnoredError(error)) {
      return;
    }

    rg4js('send', {
      error,
      customData: {
        ...(extraProperties || {}),
        basketId: this.basketId,
        clientTrackerGuid: this.bootstrap.data?.clientTrackerGuid,
        traceId: getErrObj(error)?.traceId,
        flags: {},
      },
      tags: tags.concat(this.getErrorTag(error)),
    });
  }

  /**
   * This method sends an error directly to Mixpanel. It does not cause any other
   * side effects within the system.
   */
  trackError(
    error: SomeError | undefined,
    errorDescription: string | undefined,
    event: AnalyticsEvents = AnalyticsEvents.ErrorShown,
    opts?: EventOptions
  ) {
    let errorDetails: string;
    if (isSome(error) && (error instanceof Error || typeof error === 'string')) {
      errorDetails = error?.toString();
    }

    this.analytics.trackEvent(
      event,
      () => ({
        [AnalyticsProperties.ErrorDescription]: errorDescription,
        [AnalyticsProperties.ErrorType]: getErrObj(error)?.category,
        [AnalyticsProperties.ErrorDetails]: errorDetails,
        [AnalyticsProperties.TraceId]: getErrObj(error)?.traceId,
        [AnalyticsProperties.ClientTrackerGuid]: this.bootstrap.data?.clientTrackerGuid,
      }),
      opts
    );
  }

  /**
   * Send a message to the user. If the error has an associated `detail` field,
   * use it as the message to the user; otherwise, default to `moboErr`.
   *
   * This method will send an event to Mixpanel, but will not log to raygun.
   * It will not give any additional debugging information.
   */
  sendUserMessage(err?: SomeError, defaultMsg?: string): void {
    const message = defaultMsg ?? extractErrorDetail(err) ?? MOBO_ERR;

    this.trackError(err, message);

    this.userFeedback.add({
      type: Type.Negative,
      title: DEFAULT_TITLE,
      message,
      actions: [],
    });
  }

  private async handleError(error?: any) {
    try {
      // empty promise rejections (exposes unhandled promises)
      if (!error) {
        this.appError(MOBO_ERR);

        this.sendExternalError(error, undefined, [
          RAYGUN_TAGS.MESSAGE_SOMETHING_WRONG,
          RAYGUN_TAGS.CAUSE_UNDEFINED_ERROR,
        ]);

        return;
      }

      // errors for the user
      if (errorForUser(error)) {
        this.appError(error);

        return;
      }

      // user is forbidden
      if (isStatus(Http.Status.Forbidden, error)) {
        if (!this.challenge.isChallengeActivated) {
          const isCloudflareChallenge = ErrorService.isCloudflareChallenge(error);
          if (isCloudflareChallenge) {
            this.challenge.openChallenge();
          } else {
            // We don't want users to see both a challenge and an error banner
            this.sendUserMessage(undefined, FORBIDDEN_RESPONSE_TEXT);
          }
        }

        return;
      }

      // user is unauthorized
      if (isStatus(Http.Status.Unauthorized, error)) {
        this.sendUserMessage(error, UNAUTHORIZED_ERR);

        this.router.transitionTo('login');

        return;
      }

      // server errors
      if (isServerError(error)) {
        const systemIsHealthy = await this.healthCheck.checkSystemHealth();

        if (!systemIsHealthy) {
          this.router.transitionTo('outage');
        } else {
          this.appError(MOBO_ERR);
        }

        this.sendExternalError(error, undefined, [
          RAYGUN_TAGS.MESSAGE_SOMETHING_WRONG,
          RAYGUN_TAGS.CAUSE_SERVER_ERROR,
        ]);

        return;
      }

      // JS errors that we don't want to expose to the user
      this.appError(error);
      this.sendExternalError(error, undefined, [
        RAYGUN_TAGS.MESSAGE_SOMETHING_WRONG,
        RAYGUN_TAGS.CAUSE_JS_ERROR,
      ]);
    } catch (err) {
      // The error service itself errored; make one last attempt to report the
      // error to Raygun. Don't attempt to do anything else because at this
      // point we can't trust that anything else in the error service is working
      // correctly.
      this.sendExternalError(err, undefined, [RAYGUN_TAGS.ORIGIN_ERROR_SERVICE]);
    }
  }

  //maybe we should move it out error service
  public static isCloudflareChallenge(err: AnyObject | null | undefined): boolean {
    const message = err?.errors?.[0]?.message ?? '""';
    try {
      // Try in case of JSON.parse errors
      const response = JSON.parse(message);
      if (typeof response === 'object' && 'challengeRequested' in response) {
        return response.challengeRequested;
      }
    } catch {
      return false;
    }
    return false;
  }

  /**
   * Communicates the given error to the user either through an error
   * banner or via the order criteria modal.
   */
  private appError(err?: SomeError) {
    const errObj = getErrObj(err);

    if (isSome(errObj) && isOrderCriteriaErrorCategory(errObj.category)) {
      this.trackError(errObj, errObj.message);

      if (this.basket.onPremise.isPayAtTable) {
        this.sendUserMessage(this.intl.t('mwc.errors.onPremiseCannotPayNow'));
      } else if (this.basket.onPremise.isEnabled) {
        this.sendUserMessage(this.intl.t('mwc.errors.onPremiseError'));
      } else {
        this.orderCriteria.openModal({
          error: errObj.message,
          componentNameInitiatedModal: 'Error Service',
        });
      }
    } else if (
      isSome(errObj) &&
      isPayAtTableConnectivityErrorCategory(this.basket.onPremise.isPayAtTable, errObj.category)
    ) {
      this.sendUserMessage(this.intl.t('mwc.errors.onPremiseCannotPayNow'));
    } else if (isSome(errObj) && isPayAtTableCheckAlreadyPaid(errObj?.message)) {
      const tickets = this.store.peekAll('ticket').toArray();
      // It's fine if the ticket is not found, it will end up on the Pay Route.
      // Better than erroneously showing All Paid message.
      this.onPremise.handlePaidCheck(tickets[0]?.ticketNumber);
    } else if (isSome(err) && typeof err === 'string') {
      this.sendUserMessage(err, MOBO_ERR);
    } else {
      this.sendUserMessage(err || MOBO_ERR);
    }
  }

  private isIgnoredError(err: any): boolean {
    // This happens when we get non-error API responses that aren't JSON.
    // Ember Data tries to parse as JSON, but that fails and throws an error.
    // This seems to be an intermittent back-end issue that we can't repro reliably,
    // but thankfully the error is easy to identify and ignore here.
    // Unfortunately, it relies on knowing that Ember Data sets a custom `payload`
    // property when JSON.parse fails:
    // https://github.com/emberjs/data/blob/v3.25.0/packages/adapter/addon/-private/utils/determine-body-promise.ts#L50
    const isEmberDataParseNoise = err?.name === 'SyntaxError' && !!err?.payload;

    // We ignore these Ember Data statuses because they almost always generate
    // unique errors with only one instance, which is unhelpful and noisy.
    const isEmberDataStatusNoise = [
      // E.g. the user's session timing out
      403,
      // E.g. the user typoed something in the address bar
      404,
    ].some(s => new RegExp(`Ember Data Request .* returned a ${s}`).test(err?.message));

    // We ignore TransitionAborted "error" Promises and the extremely troll-ish
    // `DS.AbortError` because both of them indicate *normal* behavior of the
    // program. Both occur when the user triggers navigation actions; neither is
    // an error that Ember.js *should* be propagating to this level. But they
    // are, so we need to ignore them.
    const isTransitionAbortedError =
      err?.name === 'TransitionAborted' || err instanceof DS.AbortError;

    return isEmberDataParseNoise || isEmberDataStatusNoise || isTransitionAbortedError;
  }

  private getErrorTag(error?: Error | string): string {
    if (!error || typeof error === 'string' || !error.stack) {
      return RAYGUN_TAGS.SOURCE_NO_STACK;
    }

    if (error.stack.includes('gtm.js')) {
      return RAYGUN_TAGS.SOURCE_GTM;
    }

    if (error.stack.includes('assets/chunk')) {
      return RAYGUN_TAGS.SOURCE_INTERNAL;
    }

    if (error.stack.includes('assets/vendor')) {
      return RAYGUN_TAGS.SOURCE_VENDOR;
    }

    return RAYGUN_TAGS.SOURCE_EXTERNAL;
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    error: ErrorService;
  }
}
