import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import {
  CodeSentCallbackResponse,
  CodeVerifiedCallbackResponse,
} from '@olo/borderless-authentication/types/Checkbox';
import pick from 'lodash.pick';

import { phoneMask } from 'mobile-web/lib/contact-number-rules';
import {
  GuestUser,
  MAX_EMAIL_LENGTH,
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
} from 'mobile-web/lib/customer';
import { isDineInJunkEmail } from 'mobile-web/lib/on-premise';
import { isOk } from 'mobile-web/lib/result';
import { classes } from 'mobile-web/lib/utilities/classes';
import Validation, {
  ValidationConfig,
  Binding,
  ValidationResult,
  EMAIL_PATTERN,
  PHONE_NUMBER_PATTERN,
} from 'mobile-web/lib/validation';
import AnalyticsService, {
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChannelService from 'mobile-web/services/channel';
import ContactService from 'mobile-web/services/contact';
import FeaturesService from 'mobile-web/services/features';
import FocusManagerService, { CategoryName } from 'mobile-web/services/focus-manager';
import OloAuthService from 'mobile-web/services/olo-auth';
import OnPremiseService, {
  ON_PREMISE_JUNK_LAST_NAME,
  ON_PREMISE_JUNK_PHONE_NUMBER,
} from 'mobile-web/services/on-premise';
import StorageService from 'mobile-web/services/storage';

import style from './index.m.scss';

class Model {
  @tracked firstName = '';
  @tracked lastName = '';
  @tracked emailAddress = '';
  @tracked contactNumber = '';
  @tracked optIn = false;
}

interface Args {
  // Required arguments
  onSubmit: (user: GuestUser) => void;

  // Optional arguments
  buttonText?: string;
  contactFieldsOnly?: boolean;
  guestUser?: GuestUser;
  optIn?: boolean;
}

interface Signature {
  Args: Args;
}

type OloAuthIdentifierConfirmationState = 'inactive' | 'active' | 'complete';

export default class GuestCheckoutForm extends Component<Signature> {
  // Service injections
  @service bootstrap!: BootstrapService;
  @service channel!: ChannelService;
  @service features!: FeaturesService;
  @service focusManager!: FocusManagerService;
  @service onPremise!: OnPremiseService;
  @service storage!: StorageService;
  @service oloAuth!: OloAuthService;
  @service analytics!: AnalyticsService;
  @service contact!: ContactService;

  // Untracked properties
  phoneMask = phoneMask;
  maxFirstNameLength = MAX_FIRST_NAME_LENGTH;
  maxLastNameLength = MAX_LAST_NAME_LENGTH;
  maxEmailLength = MAX_EMAIL_LENGTH;
  style = style;

  // Tracked properties
  @tracked model!: Model;
  @tracked validationResult?: ValidationResult;
  @tracked isDirty = false;
  @tracked submitButtonSaysContinue = false;
  @tracked codeVerified = false;
  @tracked identifierConfirmationState: OloAuthIdentifierConfirmationState = 'inactive';

  // Getters and setters
  get allowOloAccountCreation(): boolean {
    return !this.args.contactFieldsOnly && !!this.bootstrap.data?.hasOloAuthProvider;
  }

  get hidePhoneNumber(): boolean {
    return (
      this.contact.phoneNumberHiddenForDineIn ||
      (this.contact.phoneNumberHiddenForOffPrem === false && !this.contact.phoneNumberRequired)
    );
  }

  get isSubmitDisabled(): boolean {
    const model = this.model;
    const validationResult = this.validationResult;
    const hasValidationError = validationResult?.variant === 'Err';

    if (hasValidationError) {
      return true;
    }

    return (
      !model.firstName ||
      (this.contact.lastNameRequired && !model.lastName) ||
      (this.contact.emailRequired && !model.emailAddress) ||
      (this.contact.phoneNumberRequired && !model.contactNumber)
    );
  }

  get submitButtonClass() {
    return classes(style.submitButton, {
      [style.openCheckButtonContainer]: this.onPremise.hasOpenCheck,
      [style.softDisabledProccedAsGuestButton]: this.isSubmitDisabled,
    });
  }

  get validationConfig(): ValidationConfig<Model> {
    const commonBindings: Binding<Model>[] = [
      {
        targetProp: 'firstName',
        ruleName: 'notBlank',
        message: 'First name must be set',
      },
      {
        targetProp: 'lastName',
        ruleName: this.contact.lastNameRequired ? 'notBlank' : 'pass',
        message: 'Last name must be set',
      },
      {
        targetProp: 'emailAddress',
        ruleName: !this.contact.emailRequired ? 'validOptionalEmailAddress' : 'email',
        message: !this.contact.emailRequired
          ? 'Email address is not valid'
          : 'Email address must be valid',
      },
      {
        targetProp: 'contactNumber',
        ruleName: !this.contact.phoneNumberRequired ? 'validOptionalPhoneNumber' : 'phone',
        message: !this.contact.phoneNumberRequired
          ? 'Phone number is not valid'
          : 'Phone number must be valid',
      },
    ];

    return {
      bindings: commonBindings,
      customRules: [
        {
          name: 'validOptionalEmailAddress',
          check: (value: string) => !value || EMAIL_PATTERN.test(value),
        },
        {
          name: 'validOptionalPhoneNumber',
          check: (value: string) => !value || PHONE_NUMBER_PATTERN.test(value),
        },
      ],
    };
  }

  get requirePhoneVerification(): boolean {
    return this.features.flags['abtest-oloauth-require-phone-verification-olo-99676'];
  }

  // Lifecycle methods
  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const model = new Model();
    Object.assign(model, this.args.guestUser ?? {});
    model.optIn = this.args.optIn ?? false;
    if (this.contact.phoneNumberHiddenForDineIn) {
      model.contactNumber = ON_PREMISE_JUNK_PHONE_NUMBER;
    } else if (model.contactNumber === ON_PREMISE_JUNK_PHONE_NUMBER) {
      model.contactNumber = '';
    }
    if (model.lastName === ON_PREMISE_JUNK_LAST_NAME) {
      model.lastName = '';
    }
    if (isDineInJunkEmail(model.emailAddress)) {
      model.emailAddress = '';
    }
    this.model = model;

    this.analytics.trackEvent(AnalyticsEvents.ViewGuestCheckoutForm, undefined, { bucket: 'all' });
  }

  // Other methods

  // Tasks

  // Actions and helpers
  @action
  confirm(e: Event) {
    e.preventDefault();
    this.isDirty = true;

    const oloAuthCheckboxEnabled =
      this.oloAuth.lastCheckedState &&
      this.allowOloAccountCreation &&
      this.requirePhoneVerification;

    if (oloAuthCheckboxEnabled && this.identifierConfirmationState === 'inactive') {
      this.oloAuth.toggleVerificationFlow();
      this.submitButtonSaysContinue = false;
      this.identifierConfirmationState = 'active';
      this.analytics.trackEvent(AnalyticsEvents.ContinueButtonClicked, undefined, {
        bucket: 'all',
      });
    } else if (this.identifierConfirmationState === 'active') {
      this.oloAuth.suggestUnchecking();
    } else {
      this.model.contactNumber = this.contact.getPhoneNumberDefault(this.model.contactNumber);
      this.validationResult = Validation.validate(this.model, this.validationConfig);

      if (isOk(this.validationResult)) {
        this.analytics.trackEvent(
          AnalyticsEvents.CheckoutProceedAsGuest,
          () => ({
            [AnalyticsProperties.CreateOloAuthAccount]: this.oloAuth.createOloAccount,
          }),
          {
            bucket: 'all',
          }
        );
        this.args.onSubmit(
          pick(this.model, 'firstName', 'lastName', 'emailAddress', 'contactNumber', 'optIn')
        );
      } else {
        this.focusManager.focusFirst(CategoryName.GuestCheckoutFormError);
      }
    }
  }

  @action
  validate() {
    schedule('afterRender', () => {
      this.validationResult = Validation.validate(this.model, this.validationConfig);
    });
  }

  @action
  quickValidation() {
    const emailOrPhoneAttribute = this.validationConfig.bindings.filter(
      obj => !!this.model[obj.targetProp]
    );

    const emailOrPhoneRulesData = {
      bindings: emailOrPhoneAttribute,
      customRules: this.validationConfig.customRules,
    };
    this.validationResult = Validation.validate(this.model, emailOrPhoneRulesData);
  }

  @action
  attachCreateOloAuthComponent(el: Element) {
    this.identifierConfirmationState = 'inactive';
    if (this.requirePhoneVerification) {
      this.submitButtonSaysContinue = this.oloAuth.lastCheckedState;
      const codeVerifiedCallback = (response: CodeVerifiedCallbackResponse) => {
        this.identifierConfirmationState = 'complete';
        this.codeVerified = true;
        this.oloAuth.codeVerifiedCallbackResponse = response;
        this.analytics.trackEvent(AnalyticsEvents.OloAuthPhoneNumberConfirmed, undefined, {
          bucket: 'all',
        });
      };
      const onCheckedCallback = (optedIn: boolean) => {
        this.oloAuth.updateCreateOloAccount(optedIn);

        if (!optedIn) {
          this.submitButtonSaysContinue = false;
          this.identifierConfirmationState = 'inactive';
        } else {
          if (this.identifierConfirmationState === 'inactive') {
            this.submitButtonSaysContinue = true;
          } else {
            this.submitButtonSaysContinue = false;
          }
        }
      };
      const codeSentCallback = (response: CodeSentCallbackResponse) => {
        this.identifierConfirmationState = 'active';
        if (response.resent) {
          this.analytics.trackEvent(AnalyticsEvents.OloAuthResendPhoneVerifyCode, undefined, {
            bucket: 'all',
          });
        }
      };
      this.oloAuth.attachCreateAccountCheckbox({
        el,
        onChecked: onCheckedCallback,
        onCodeSent: codeSentCallback,
        onCodeVerified: codeVerifiedCallback,
        requireVerification: true,
        email: this.model.emailAddress,
        phoneNumber: this.model.contactNumber,
      });
    } else {
      this.oloAuth.attachCreateAccountCheckbox({
        el,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    GuestCheckoutForm: typeof GuestCheckoutForm;
  }
}
