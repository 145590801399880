import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import DS from 'ember-data';

import TicketModel from 'mobile-web/models/ticket';
import OnPremiseService from 'mobile-web/services/on-premise';
import VendorService from 'mobile-web/services/vendor';

type Params = { vendor_slug: string; ticketNumber: string };

export default class PaySelectRoute extends Route {
  @service onPremise!: OnPremiseService;
  @service router!: RouterService;
  @service store!: DS.Store;
  @service vendor!: VendorService;

  beforeModel(transition: Transition) {
    const params = transition.to.params as Params;
    const table = transition.to.queryParams.tableNumber;
    const ticket = transition.to.queryParams.ticketNumber;

    if (!table && !ticket) {
      this.router.transitionTo('pay', params.vendor_slug);
    }
  }

  async model(params: Params, transition: Transition) {
    const tableNumber = transition.to.queryParams.tableNumber ?? '';
    const ticketNumber = transition.to.queryParams.ticketNumber ?? '';

    await this.vendor.ensureVendorLoaded(params.vendor_slug);
    let checks: TicketModel[] = [];

    if (tableNumber) {
      checks = (await this.onPremise.fetchTickets(tableNumber)).toArray();
      checks.sort((a, b) => (a.totals.due > 0 ? -1 : 1) - (b.totals.due > 0 ? -1 : 1));
    } else if (ticketNumber) {
      const ticketAdapter = this.store.adapterFor('ticket');
      const response = await ticketAdapter.getTicket(ticketNumber);
      checks = [response.ticket];
    }

    //exactly one unpaid check, go directly to checkout
    if (checks.length === 1 && checks[0].totals.due > 0) {
      await this.onPremise.payAtTableCheckout(checks[0]);
      transition.abort();
    }

    return { checks, tableNumber };
  }
}
