import DS from 'ember-data';

import { HandoffMode } from 'mobile-web/lib/order-criteria';
import { LoginProvider, ChannelSettings } from 'mobile-web/models/bootstrap-data';
import HandoffModeModel from 'mobile-web/models/handoff-mode';
import Vendor from 'mobile-web/models/vendor';

export default class ChannelModel extends DS.Model {
  @DS.attr('string')
  guid!: string;
  @DS.attr('string')
  name!: string;
  @DS.attr('string')
  internalName!: string;
  @DS.attr('string')
  cdnUrl!: string;
  @DS.attr('string')
  googleAnalyticsCode?: string;
  @DS.attr('string')
  googleTagManagerCode?: string;
  @DS.attr('string')
  iOSAppIdentifier?: string;
  @DS.attr('string')
  iOSMinAppVersion?: string;
  @DS.attr('string')
  androidAppIdentifier?: string;
  @DS.attr('string')
  androidMinAppVersion?: string;
  @DS.attr()
  settings!: ChannelSettings;
  @DS.attr('array')
  loginProviders!: LoginProvider[];
  @DS.attr('string')
  defaultHandoffMode?: HandoffMode;

  @DS.hasMany('vendor')
  vendors!: Vendor[];
  /**
   * WARNING
   * Note that this is NOT "all handoff modes", i.e. it is not guaranteed to
   * return the same list as `this.store.peekAll('handoff-mode')`.
   * It's more akin to "all public handoff modes", i.e. all handoff modes which
   * are used by at least one public vendor.
   */
  @DS.hasMany('handoff-mode')
  handoffModes!: HandoffModeModel[];
}
