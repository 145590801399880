import DS from 'ember-data';
import { ModelRegistry } from 'ember-data/model';
import RSVP from 'rsvp';

import ApplicationAdapter from 'mobile-web/adapters/application';
import BasketModel from 'mobile-web/models/basket';

export type GetTimeSlotsResponse = {
  leadTimeEstimate: number;
  /** An array of ISO 8601 UTC datetime strings */
  timeSlots: string[];
  vendorUtcOffset: number;
  ignoreUtcOffset: boolean;
};

export default class BasketAdapter extends ApplicationAdapter {
  ajax(
    url: string,
    type: string,
    options?: { data: { vendorId?: EmberDataId } }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): RSVP.Promise<any> {
    if (type.toLowerCase() === 'transfer' && options) {
      const vendorId = options.data.vendorId;
      delete options.data.vendorId;
      return super.ajax(`${url}?vendorId=${vendorId}`, 'PUT', options);
    }
    return super.ajax(url, type, options);
  }

  urlForCreateRecord<K extends keyof ModelRegistry>(
    modelName: K,
    snapshot: DS.Snapshot<K>
  ): string {
    const baseUrl = super.urlForCreateRecord(modelName, snapshot);
    const baseModel = snapshot.record as BasketModel;

    if (baseModel.referralToken) {
      return `${baseUrl}?vendorId=${baseModel.vendor.get('id')}&referralToken=${
        baseModel.referralToken
      }`;
    }
    return `${baseUrl}?vendorId=${baseModel.vendor.get('id')}`;
  }
}
